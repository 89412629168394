import firebase from "firebase/compat/app";
import { CircularProgress } from "@mui/material";
import EnvGatherer from "./EnvGatherer";
import { getAnalytics } from "firebase/analytics";

const app = firebase.initializeApp({
  apiKey: EnvGatherer.REACT_APP_API_KEY,
  authDomain: EnvGatherer.REACT_APP_AUTH_DOMAIN,
  databaseURL: EnvGatherer.REACT_APP_DATABASE_URL,
  projectId: EnvGatherer.REACT_APP_PROJECT_ID,
  storageBucket: EnvGatherer.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: EnvGatherer.REACT_APP_MESSAGING_SENDER,
  appId: EnvGatherer.REACT_APP_APP_ID,
  measurementId: EnvGatherer.REACT_APP_MEASUREMENT_ID,
});

export const analytics = getAnalytics(app);

export default app;

export function LoadingScreen() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
      }}
    >
      <div>
        <CircularProgress size={100} color={"success"} />
      </div>
    </div>
  );
}
