import React, { useState } from "react";
import "firebase/compat/functions";
import { Box, Grid, Paper } from "@mui/material";
import betalningsmetoder from "../media/betalningsmetoder-footer.webp";

import { useTranslation } from "react-i18next";

//   <label  onClick={() => {window.open("https://www.facebook.com/profile.php?id=100090693737485")}} style={{color: "black", cursor: "pointer"}}><FacebookIcon/>Facebook</label>
//                 <label onClick={() => {window.open("https://www.instagram.com/halkan.se/")}}  style={{color: "black", cursor: "pointer"}}><InstagramIcon/>Instagram </label>
export default function Footer() {
  const { t } = useTranslation();
  const [cookies, setCookies] = useState(false);
  const [tos, setTos] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        maxWidth: "100vw",
        alignItems: "flex-end",
      }}
    >
      {tos === true && <TOS />}
      {cookies === true && <Cookies />}
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#f2f9fe",
          alignItems: "center",
          padding: 2,
          paddingRight: { xl: "20vw" },
          paddingLeft: { xl: "20vw" },
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <label>Halkan.se© | 2023</label>
          <label>
            Telefonnummer: <a href="tel:+46737293293">0737 293 293</a>{" "}
          </label>
          <label>info@halkan.se</label>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-end" },
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            alignContent: { xs: "center", md: "flex-end" },
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <img
            alt={"Betalningsmetoder"}
            src={betalningsmetoder}
            style={{ maxWidth: 300 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            maxHeight: "fit-content",
            marginTop: 0,
            paddingTop: 0,
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            gap: 2,
          }}
        >
          <i
            onClickCapture={() => {}}
            style={{
              color: "black",
              textDecoration: "underline",
              fontSize: 10,
              cursor: "pointer",
            }}
            onClick={() => setTos(true)}
          >
            {t("footer.tos")}
          </i>

          <i
            onClickCapture={() => {}}
            style={{
              color: "black",
              textDecoration: "underline",
              fontSize: 10,
              cursor: "pointer",
            }}
            onClick={() => setCookies(true)}
          >
            {t("footer.cookies")}
          </i>
        </Grid>
      </Grid>
    </Box>
  );

  function TOS() {
    return (
      <div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "fixed",
          zIndex: 1000,
          backgroundColor: "rgba(0,0,0,0.56)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15vh",
        }}
      >
        <Paper
          style={{
            height: "80vh",
            width: "80vw",
            overflowY: "scroll",
            padding: 10,
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <h5 style={{ textAlign: "left", fontWeight: "bold", flex: 1 }}>
              {t("footer.tos")}
            </h5>
            <h4
              onClick={() => setTos(false)}
              style={{ textAlign: "right", flex: 1, cursor: "pointer" }}
            >
              x
            </h4>
          </div>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            Om företaget
          </h5>

          <p>
            Halkan.se drivs av AE Körkortsgruppen AB (organisationsnummer
            559407-5912, Adress Hyllie Boulevard 53, 215 37, Malmö). Ingen
            information eller bilder eller liknande på hemsidan får kopieras
            utan tillåtelse.
          </p>

          <h5 style={{ textAlign: "left", fontWeight: "bold" }}> Priser</h5>
          <p>
            Alla priser som anges på Halkan.se är i svenska kronor inklusive
            mervärdesskatt (moms). AE Körkortsgruppen AB reserverar sig rätten
            att ändra priserna när som helst utan föregående avisering. När en
            bokning görs, är det priset som gäller vid det tillfället det
            bindande priset.
          </p>

          <h5 style={{ textAlign: "left", fontWeight: "bold" }}> Betalning</h5>
          <p>
            {" "}
            För närvarande erbjuder vi Klarna och Swish som
            betalningsalternativ. Du kan använda Klarna för att betala med kort,
            faktura, direktbetalning eller delbetala. Vi erbjuder även
            möjligheten att betala med Swish, en enkel och bekväm betalmetod via
            mobilen.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Bokningsbekräftelse
          </h5>
          <p style={{}}>
            {" "}
            Efter lyckad bokning får du en bokningsbekräftelse via e-post. I
            bekräftelsen finns information om din bokning om bland annat datum
            för kursen, tid, pris och avbokningsregler. Har du valt till gratis
            transport så finns även information om den i din
            bokningsbekräftelse. När du har fått din bokningsbekräftelse betyder
            det att ett bindande avtal har ingåtts mellan dig och AE
            Körkortsgruppen AB.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}> Transport</h5>
          <p>
            Vi erbjuder gratis transport till och från utvalda platser. För att
            få gratis transport till och från trafikövningsplatsen/halkbanan så
            väljer du till det vid bokning. Om du valt gratis transport och valt
            att inte använda dig av den så ska du meddela oss detta snarast
            genom att skicka ett mail till info@halkan.se Om du valt att ta dig
            till trafikövningsplatsen själv och istället vill ha gratis
            transport så ska du även i detta fall kontakta oss genom mail på
            info@halkan.se Observera att vi i detta fall inte kan garantera att
            erbjuda dig transport. Har kunden inte fått ett bekräftelsemail om
            transporten så är det kundens ansvar att ta sig till
            halkbanan/trafikövningsplatsen.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}> Ångerrätt</h5>
          <p>
            Vi lämnar alltid 14 dagars ångerrätt på alla köp med förbehåll för
            att avbokning måste ske senast 72 timmar innan kursstart. Om en kurs
            avbokas senare än 72 timmar innan kursstart förbehåller vi oss
            rätten debitera hela avgiften för kursen.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}> Avbokning</h5>
          <p>
            Avbokning av kurs ska ske senast 72 timmar innan kursstart.
            Avbokning sker genom mail till info@halkan.se. I mailet ska du ange
            namn och personnummer eller bokningsid. Avbokning kan ej ske via
            halkbanan/trafikövningsplatsen.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Återbetalning
          </h5>
          <p>
            Vi behandlar normalt sett en återbetalning inom några arbetsdagar
            efter att vi bekräftat avbokningen. Återbetalningen sker via samma
            betalmetod som du använde dig av vid bokningen.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Krav på kursdeltagare
          </h5>
          <p>
            För att du ska få delta på kursen så ska betalning vara erlagd, du
            ska vara i tid samt behärska språket som kursen hålls på. Du ska
            även ha med dig giltig id handling. Regler och säkerhetsföreskrifter
            på halkbanan/trafikövningsplatsen ska följas liksom instruktörens
            instruktioner. För att bli godkänd så ska du uppfylla
            transportstyrelsens krav om vad som krävs för att bli godkänd. Det
            innebär att du måste kunna köra och manövrera en bil självständigt,
            att du deltar aktivt samt är närvarande under hela kursens gång. Den
            som håller i kursen vid kurstillfället är den som avgör om eleven
            uppnår alla krav/mål.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Under 18 år
          </h5>
          <p>
            Om du är under 18 år vid bokningstillfället så måste du ha någon av
            dina vårdnadshavares samtycke att ingå i avtal med AE
            Körkortsgruppen AB. Genom att godkänna användarvillkoren bekräftar
            du att du har din vårdnadshavares samtycke att ingå avtal med med
            oss.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Rapportering till Transportstyrelsen
          </h5>
          <p>
            Ansvarig för rapportering av risktvåan/halkan till
            Transportstyrelsen är halkbanan/trafikövningsplatsen. Rapportering
            sker vanligtvis samma dag som kursen genomförs.
          </p>
          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Ändring av tid eller inställd kurs
          </h5>
          <p>
            Vi förbehåller oss rätten att ställa in en kurs om vi får för få
            deltagare till kursen eller pga sjukdom eller andra oförutsedda
            omständigheter som vi inte kan påverka. Vid inställd kurs har du
            rätt att få en ny tid eller välja att få en full återbetalning. Vid
            ändring av tid för kursen har du rätt att välja att få en full
            återbetalning istället.
          </p>

          <h5 style={{ textAlign: "left", fontWeight: "bold" }}>
            {" "}
            Identitetshandling{" "}
          </h5>
          <p style={{ marginBottom: 0 }}>
            Vi godtar följande identitetshandlingar:
          </p>
          <ul style={{ textAlign: "justify", marginTop: 0 }}>
            <li>Svenskt EU-pass</li>
            <li> Svensk nationellt id-kort (NID-kort)</li>
            <li> ID-kort utfärdat av Skatteverket</li>
            <li> Svenskt körkort</li>
            <li> SIS-märkta företags-, tjänste- eller id-kort</li>
            <li>
              {" "}
              Övriga EU-pass samt pass från Färöarna, Förenade kungariket
              (Storbritannien och Nordirland), Island, Liechtenstein, Norge och
              Schweiz.
            </li>
          </ul>
        </Paper>
      </div>
    );
  }

  function Cookies() {
    return (
      <div
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "fixed",
          zIndex: 1000,
          backgroundColor: "rgba(0,0,0,0.56)",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "15vh",
        }}
      >
        <Paper
          style={{
            height: "80vh",
            width: "80vw",
            overflowY: "scroll",
            padding: 10,
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <h2 style={{ textAlign: "left", flex: 1, fontWeight: "bold" }}>
              {t("footer.cookies")}
            </h2>
            <h4
              onClick={() => setCookies(false)}
              style={{ textAlign: "right", flex: 1, cursor: "pointer" }}
            >
              x
            </h4>
          </div>
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            Cookies Policy
          </h3>
          <br />
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            Användning av nödvändiga cookies{" "}
          </h5>
          <p>
            Vi använder endast nödvändiga cookies på vår webbplats för att
            säkerställa att webbplatsen fungerar korrekt och för att förbättra
            din användarupplevelse. Nödvändiga cookies används för att lagra
            information om ditt besök på vår webbplats, inklusive dina
            preferenser för språk. Dessa cookies kan inte användas för
            marknadsföringsändamål och samlar inte in personlig information.
            Genom att använda vår webbplats samtycker du till vår användning av
            dessa cookies.
          </p>
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            Användning av Google Analytics-cookies
          </h5>
          <p>
            Vi använder också tredjepartscookies från Google Analytics för att
            samla in information om hur besökare använder vår webbplats. Denna
            information hjälper oss att förstå hur besökare interagerar med vår
            webbplats och hur vi kan förbättra den. Google Analytics-cookies
            samlar inte in personlig information, utan endast information om
            besökarens geografiska plats, webbläsare, enhet och vilka sidor som
            besökts. Genom att använda vår webbplats samtycker du till vår
            användning av Google Analytics-cookies.
          </p>
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            {" "}
            Hantering av cookies i din webbläsare
          </h5>
          <p>
            Du kan blockera eller radera cookies genom att ändra inställningarna
            i din webbläsare. Observera dock att om du blockerar cookies kan
            vissa delar av vår webbplats sluta att fungera korrekt.
            <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
              {" "}
              Privacy Policy
            </h3>
            Vår åtagande för att skydda din integritet Vi värnar om din
            integritet och behandlar all data som samlas in från vår webbplats
            med stor försiktighet.
          </p>
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            {" "}
            Hantering av personuppgifter
          </h5>
          <p>
            Vi säljer eller delar inte din personliga information med tredje
            part. Vi sparar dina uppgifter endast så länge som det är nödvändigt
            för att uppfylla våra åtaganden gentemot dig som kund och i enlighet
            med tillämpliga lagar och förordningar.
          </p>
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            Säkerhet av personuppgifter
          </h5>
          <p>
            Vi använder lämpliga tekniska och organisatoriska säkerhetsåtgärder
            för att skydda dina personuppgifter mot obehörig åtkomst, missbruk,
            förlust eller ändring. Vi skyddar din personliga information genom
            att använda säkra protokoll för dataöverföring och lagring.
          </p>
          <h5 style={{ fontWeight: "bold", textAlign: "left" }}>
            {" "}
            Information som samlas in vid bokning
          </h5>
          <p>
            Vi samlar in följande information om dig när du gör en bokning på
            vår webbplats: ditt namn, din adress, ditt telefonnummer, ditt
            personnummer och din e-postadress. Denna information används för att
            kunna genomföra din bokning och för att skicka information om
            bokningen till dig.
          </p>
        </Paper>
      </div>
    );
  }
}
